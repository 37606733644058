import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import SEO from 'components/templates/SEO';
import Layout from 'components/templates/Layout';
import { Disqus } from 'gatsby-plugin-disqus';
import { Typography } from '@mui/material';

interface BlogProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteUrl: string;
      };
    };
    markdownRemark: {
      frontmatter: any;
      html: string;
      comment: boolean | undefined;
    };
  };
  pageContext: {
    prev?: any;
    next?: any;
    slug: string;
    title: string;
    url: string;
  };
}

const getUrl = (node: any) => {
  return `/post/${decodeURIComponent(
    node.frontmatter.slug || node.frontmatter.title
  )}`;
};

export default ({ data, pageContext }: BlogProps) => {
  const { markdownRemark, site } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  // @ts-ignore
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        meta={[
          {
            name: 'keywords',
            content: frontmatter.tags?.join(',') || '',
          },
        ]}
      />
      <div className="blog-post-container">
        <div className="blog-post">
          <Typography variant="h1" color="primary">
            {frontmatter.title}
          </Typography>
          <Typography variant="h4" color="primary" className="my-4">
            {frontmatter.date}
          </Typography>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <div className="flex justify-between my-8">
        {pageContext.prev !== null && (
          <Link to={getUrl(pageContext.prev)}>{`<< `} 上一篇</Link>
        )}
        <div className="flex flex-grow" />
        {pageContext.next !== null && (
          <Link to={getUrl(pageContext.next)}>下一篇 {` >>`}</Link>
        )}
      </div>
      {markdownRemark.comment !== false && (
        <Disqus
          config={{
            /* Replace PAGE_URL with your post's canonical URL variable */
            url: `${site.siteMetadata.siteUrl}${pageContext.url}`,
            /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
            identifier: pageContext.slug,
            /* Replace PAGE_TITLE with the title of the page */
            title: pageContext.title,
          }}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($title: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
      }
    }
  }
`;
